import styled from 'styled-components';
import { Row, Col, Typography } from 'antd';
import Card from './Card';
import AddNewButton from './AddNewButton';

const { Title } = Typography;

const RolesSectionWrapper = styled.div`
  width: 100%;
  height: 100%;

  .ant-typography {
    margin-bottom: 0;
  }
`;

const cardsContent = [
  { title: 'Employee', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do' },
  { title: 'CEO', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing' },
  { title: 'Admin Dept Head', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ipsum.' },
  { title: 'Adminstrator', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do' },
  { title: 'COO', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing' },
  { title: 'Account & Finance Dept Head', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing' },
];

const RolesSection = (): JSX.Element => {
  return (
    <RolesSectionWrapper>
      <Row wrap={false} align="middle" justify="space-between" style={{ marginBottom: 28 }}>
        <Col>
          <Title level={3}>Roles</Title>
        </Col>
        <Col>
          <AddNewButton onClick={() => console.log('Add New Role clicked!')} buttonText="Add New Role" />
        </Col>
      </Row>
      <Row gutter={[20, 20]} align="middle" justify="start">
        {cardsContent.map((obj, i) => (
          <Col flex="0 1 244px" key={i}>
            <Card title={obj.title} description={obj.description} />
          </Col>
        ))}
      </Row>
    </RolesSectionWrapper>
  );
};

export default RolesSection;
