import styled from 'styled-components';
import { EyeOutlined } from '@ant-design/icons';
import { getTimesheets } from '../data/timesheets';
import { Row, Col, Table, Typography, Input } from 'antd';
import AddNewButton from './AddNewButton';

const { Title } = Typography;

const TimesheetsSectionWrapper = styled.div`
  input {
    width: 391px;
    height: 53px;

    background: rgba(5, 31, 36, 0.05);
    border: 1px solid rgba(5, 31, 36, 0.05);
    border-radius: 5px;
  }

  .table-wrapper {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
  }

  th.ant-table-cell {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;

    color: #9ab1b6;
    background-color: #fff;

    &::before {
      background-color: #fff !important;
    }
  }

  td.ant-table-cell {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: #051f24;
  }
`;

const columns = [
  {
    title: 'Timesheet Name',
    dataIndex: 'timesheetName',
    key: 'timesheetName',
  },
  {
    title: 'From Date',
    dataIndex: 'fromDate',
    key: 'fromDate',
  },
  {
    title: 'To Date',
    dataIndex: 'toDate',
    key: 'toDate',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => <p style={{ color: status === 'In Progress' ? '#07697D' : '#7D1C07' }}>{status}</p>,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: () => (
      <div style={{ width: '100%', cursor: 'pointer' }}>
        <EyeOutlined style={{ marginLeft: '20px', color: '#07697D' }} />
      </div>
    ),
  },
];

const timesheets = getTimesheets(30);

const TimesheetsSection = (): JSX.Element => {
  return (
    <TimesheetsSectionWrapper>
      <Row align="middle" justify="space-between" style={{ marginBottom: 24 }}>
        <Col>
          <Input size="large" placeholder="Search ..." />
        </Col>
        <Col>
          <AddNewButton onClick={() => console.log('Add New Timesheet clicked')} buttonText="Add New Timesheet" />
        </Col>
      </Row>
      <div className="table-wrapper">
        <Title level={3} style={{ marginBottom: 30 }}>
          TimeSheets
        </Title>
        <Table columns={columns} dataSource={timesheets} />
      </div>
    </TimesheetsSectionWrapper>
  );
};

export default TimesheetsSection;
