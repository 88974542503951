import { Divider, Row, Col, Typography } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  EditOutlined,
  HeatMapOutlined,
  AuditOutlined,
  PhoneOutlined,
  BorderOuterOutlined,
  FileOutlined,
  LockOutlined,
  FlagOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';

const { Text } = Typography;

const SettingsPanelWrapper = styled.div`
  width: 297px;
  height: auto;

  background: #ffffff;
  border-radius: 10px;
  padding: 26px;

  h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #051f24;
    margin-bottom: 32px;
  }

  .ant-row {
    margin-bottom: 24px;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: rgba(5, 31, 36, 0.6);

    .ant-typography {
      color: inherit;
    }
  }
`;

const settingsOptions = {
  general: [
    {
      to: '/admin/departments',
      icon: <HeatMapOutlined />,
      text: 'Departments',
    },
    {
      to: '/admin/settings',
      icon: <EditOutlined />,
      text: 'Divisions',
    },
    {
      to: '/admin/settings',
      icon: <AuditOutlined />,
      text: 'Degrees',
    },
    {
      to: '/admin/settings',
      icon: <PhoneOutlined />,
      text: 'Emergency Contacts',
    },
    {
      to: '/admin/settings',
      icon: <BorderOuterOutlined />,
      text: 'Employment Types',
    },
    {
      to: '/admin/settings',
      icon: <FlagOutlined />,
      text: 'Employment Status',
    },
    {
      to: '/admin/settings',
      icon: <LockOutlined />,
      text: 'Work Authorizations',
    },
    {
      to: '/admin/settings',
      icon: <FileOutlined />,
      text: 'Document Types',
    },
  ],
  security: [
    {
      to: '/admin/roles',
      icon: <LockOutlined />,
      text: 'Roles',
    },
    {
      to: '/admin/settings',
      icon: <UsergroupAddOutlined />,
      text: 'Users',
    },
  ],
};

const SettingsPanel = (): JSX.Element => {
  return (
    <SettingsPanelWrapper>
      <h4>General</h4>
      {settingsOptions.general.map((obj, i) => (
        <Link to={obj.to} key={i}>
          <Row gutter={[20, 20]}>
            <Col>{obj.icon}</Col>
            <Col>
              <Text>{obj.text}</Text>
            </Col>
          </Row>
        </Link>
      ))}
      <Divider />
      <h4>Security</h4>
      {settingsOptions.security.map((obj, i) => (
        <Link to={obj.to} key={i}>
          <Row gutter={[20, 20]}>
            <Col>{obj.icon}</Col>
            <Col>
              <Text>{obj.text}</Text>
            </Col>
          </Row>
        </Link>
      ))}
    </SettingsPanelWrapper>
  );
};

export default SettingsPanel;
