import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';

const AddNewButtonWrapper = styled.div`
  width: auto;
  min-height: 53px;
  border: 1px solid rgba(7, 105, 125, 0.25);
  border-radius: 5px;
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #07697d;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }

  p {
    margin: 0;
  }

  svg,
  p {
    color: inherit;
  }
`;

interface AddNewButtonProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  buttonText: string;
}

const AddNewButton = ({ onClick, buttonText }: AddNewButtonProps): JSX.Element => {
  return (
    <AddNewButtonWrapper onClick={onClick}>
      <PlusOutlined />
      <p>{buttonText}</p>
    </AddNewButtonWrapper>
  );
};

export default AddNewButton;
