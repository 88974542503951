import styled from 'styled-components';
import { Row, Col, Typography } from 'antd';

import SettingsPanel from './SettingsPanel';

const { Title } = Typography;

const SettingsSectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 12px;

  .ant-typography {
    margin-bottom: 0;
  }
`;

const SettingsSection = (): JSX.Element => {
  return (
    <SettingsSectionWrapper>
      <Row wrap={false} align="middle" justify="space-between" style={{ marginBottom: 28 }}>
        <Col>
          <Title level={3}>Settings</Title>
        </Col>
      </Row>
      <Row align="middle" justify="start">
        <SettingsPanel />
      </Row>
    </SettingsSectionWrapper>
  );
};

export default SettingsSection;
