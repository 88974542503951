import faker from 'faker';

export const getTimesheets = (count: number) => {
  return new Array(count).fill(0).map((_, i) => ({
    id: faker.datatype.uuid(),
    timesheetName: 'Feb - 2021 - Week - 4',
    fromDate: 'Feb 22, 2021',
    toDate: 'Feb 28, 2021',
    status: faker.random.arrayElement(['In Progress', 'Not Started']),
  }));
};
