import styled from 'styled-components';
import { Row, Col, Typography } from 'antd';
import Card from './Card';
import AddNewButton from './AddNewButton';

const { Title } = Typography;

const DepartmentsSectionWrapper = styled.div`
  width: 100%;
  height: 100%;

  .ant-typography {
    margin-bottom: 0;
  }
`;

const cardsContent = [
  { title: 'Management', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do' },
  { title: 'HR', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing' },
  { title: 'IT', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ipsum.' },
  { title: 'Finance', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do' },
  { title: 'Sales', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing' },
];

const DepartmentsSection = (): JSX.Element => {
  return (
    <DepartmentsSectionWrapper>
      <Row wrap={false} align="middle" justify="space-between" style={{ marginBottom: 28 }}>
        <Col>
          <Title level={3}>Departments</Title>
        </Col>
        <Col>
          <AddNewButton onClick={() => console.log('Add New Department clicked!')} buttonText="Add New Department" />
        </Col>
      </Row>
      <Row gutter={[20, 20]} align="middle" justify="start">
        {cardsContent.map((obj, i) => (
          <Col flex="0 1 244px" key={i}>
            <Card title={obj.title} description={obj.description} />
          </Col>
        ))}
      </Row>
    </DepartmentsSectionWrapper>
  );
};

export default DepartmentsSection;
