import { Row, Col, Divider, Typography } from 'antd';
import styled from 'styled-components';

import { EditOutlined } from '@ant-design/icons';

const { Title } = Typography;

const CardWrapper = styled.div`
  width: 244px;
  min-height: 165px;

  background: #ffffff;
  border: 1px solid rgba(5, 31, 36, 0.1);
  border-radius: 10px;

  svg {
    color: #07697d;
  }

  .ant-row {
    padding: 16px;

    .ant-typography {
      margin-bottom: 0;
      width: 170px;
    }
  }

  .ant-divider.ant-divider-horizontal {
    margin: 0;
  }
`;

interface CardProps {
  title: string;
  description: string;
}

const Card = ({ title, description }: CardProps): JSX.Element => {
  return (
    <CardWrapper>
      <Row align="top" justify="space-between">
        <Col>
          <Title level={5}>{title}</Title>
        </Col>
        <Col>
          <EditOutlined />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col flex="auto">{description}</Col>
      </Row>
    </CardWrapper>
  );
};

export default Card;
