import styled from 'styled-components';
import { Row, Col, Typography } from 'antd';
import Card from './Card';
import AddNewButton from './AddNewButton';

const { Title } = Typography;

const DocumentsSectionWrapper = styled.div`
  width: 100%;
  height: 100%;

  .ant-typography {
    margin-bottom: 0;
  }
`;

const cardsContent = [
  { title: 'Tax', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do' },
  { title: 'Pay', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing' },
  { title: 'HR', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ipsum.' },
];

const DocumentsSection = (): JSX.Element => {
  return (
    <DocumentsSectionWrapper>
      <Row wrap={false} align="middle" justify="space-between" style={{ marginBottom: 28 }}>
        <Col>
          <Title level={3}>Document Types</Title>
        </Col>
        <Col>
          <AddNewButton
            onClick={() => console.log('Add New Document Type clicked!')}
            buttonText="Add New Document Type"
          />
        </Col>
      </Row>
      <Row gutter={[20, 20]} align="middle" justify="start">
        {cardsContent.map((obj, i) => (
          <Col flex="0 1 244px" key={i}>
            <Card title={obj.title} description={obj.description} />
          </Col>
        ))}
      </Row>
    </DocumentsSectionWrapper>
  );
};

export default DocumentsSection;
