import faker from 'faker';

export const getEmployees = (count: number) => {
  return new Array(count).fill(0).map((_, i) => ({
    id: faker.datatype.uuid(),
    avatar: `https://i.pravatar.cc/44?u=${faker.datatype.uuid()}`,
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    email: faker.internet.email(),
    phoneNumber: faker.phone.phoneNumber('(###) ###-####'),
    status: faker.random.arrayElement(['Active', 'Inactive']),
  }));
};
