import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import 'antd/dist/antd.css';

import AdminContainer from './containers/admin/Admin';

const App = () => {
  return (
    <div>
      <Router>
        <Redirect from="/" to="/admin" />
        <Switch>
          <Route path="/admin" component={AdminContainer} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
