import { useState } from 'react';
import styled from 'styled-components';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { Divider, Layout, Menu, Typography } from 'antd';
import {
  HomeOutlined,
  LineChartOutlined,
  UsergroupAddOutlined,
  InfoCircleOutlined,
  BarsOutlined,
  BuildOutlined,
  MessageOutlined,
  ClockCircleOutlined,
  ReadOutlined,
  BellOutlined,
  SwapOutlined,
  SettingOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

import ComingSoon from '../../components/ComingSoon';

import hrezeLogo from '../../assets/logos/hreze.svg';
import profileImage from '../../assets/images/profile-image.png';
import DocumentsSection from '../../components/DocumentsSection';
import DepartmentsSection from '../../components/DepartmentsSection';
import RolesSection from '../../components/RolesSection';
import SettingsSection from '../../components/SettingsSection';
import EmployeesSection from '../../components/EmployeesSection';
import TimesheetsSection from '../../components/TimesheetsSection';

const { Header, Content, Sider } = Layout;
const { Title, Text } = Typography;

const AdminContainerWrapper = styled.div`
  width: 100%;
  min-height: 100vh;

  .admin-container {
    width: 100%;
    min-height: 100vh;
  }

  .content-container {
    padding: 24px;
    width: 100%;
    height: 100%;

    min-width: 768px;
  }

  .header {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
    }

    .sidebar-trigger {
      float: left;
      width: 40px;
      height: 40px;
      margin: 8px 24px 8px 0;

      display: grid;
      place-items: center;

      svg {
        width: 30px;
        height: 30px;
        color: #07697d;
      }
      cursor: pointer;
    }

    .logo {
      float: left;
      width: 120px;
      height: 31px;
      margin: 16px 24px 16px 0;

      display: grid;
      place-items: center;
    }

    .profile-image {
      float: right;

      img {
        width: 44px;
        height: 44px;

        border-radius: 10px;
      }
    }
  }

  .sidebar {
    border-top: 1px solid rgba(5, 31, 36, 0.1);
    overflow-y: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .user-info {
    padding: 30px 0 10px 24px;

    img {
      width: 76px;
      height: 76px;
      border-radius: 15px;
      margin-bottom: 20px;
    }
  }

  .manage {
    padding: 0px 0 0px 24px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #9ab1b6;
  }
`;

const AdminContainer = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const { path, url } = useRouteMatch();

  const history = useHistory();

  return (
    <AdminContainerWrapper>
      <Layout id="admin-container" className="admin-container">
        <Header className="header">
          <div>
            <div className="sidebar-trigger" onClick={() => setIsSidebarCollapsed((prevState) => !prevState)}>
              {isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </div>
            <div className="logo">
              <img src={hrezeLogo} alt="HReze" />
            </div>
          </div>
          <div className="profile-image">
            <img src={profileImage} alt="Profile" />
          </div>
        </Header>
        <Layout>
          <Sider
            theme="light"
            className="sidebar"
            breakpoint="lg"
            collapsedWidth={0}
            collapsible
            trigger={null}
            collapsed={isSidebarCollapsed}
          >
            <div className="logo" />
            <Menu theme="light" mode="inline" onSelect={({ key }) => history.push(`${url}/${key}`)}>
              <div className="user-info">
                <img src={profileImage} alt="" />
                <Title level={4}>Jane Cooper</Title>
                <Text>Enterprise Architect</Text>
              </div>
              <Divider />
              <Menu.Item key="overview" icon={<HomeOutlined />}>
                Overview
              </Menu.Item>
              <Menu.Item key="dashboards" icon={<LineChartOutlined />}>
                Dashboards
              </Menu.Item>
              <Menu.Item key="employees" icon={<UsergroupAddOutlined />}>
                Employees
              </Menu.Item>
              <Menu.Item key="my-info" icon={<InfoCircleOutlined />}>
                My Info
              </Menu.Item>
              <Menu.Item key="my-activities" icon={<BarsOutlined />}>
                My Activities
              </Menu.Item>
              <Menu.Item key="self-services" icon={<BuildOutlined />}>
                Self Services
              </Menu.Item>
              <Menu.Item key="communications" icon={<MessageOutlined />}>
                Communications
              </Menu.Item>
              <Menu.Item key="timesheets" icon={<ClockCircleOutlined />}>
                TimeSheets
              </Menu.Item>
              <Menu.Item key="documents" icon={<ReadOutlined />}>
                Documents
              </Menu.Item>
              <Divider />
              <p className="manage">Manage</p>
              <Menu.Item key="notifications" icon={<BellOutlined />}>
                Notifications
              </Menu.Item>
              <Menu.Item key="accounts" icon={<SwapOutlined />}>
                Accounts
              </Menu.Item>
              <Menu.Item key="settings" icon={<SettingOutlined />}>
                Settings
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout style={{ minWidth: 768 }}>
            <Content style={{ minWidth: 768 }}>
              <div className="content-container">
                <Switch>
                  <Route path={`${path}/employees`} component={EmployeesSection} />
                  <Route path={`${path}/timesheets`} component={TimesheetsSection} />
                  <Route path={`${path}/roles`} component={RolesSection} />
                  <Route path={`${path}/departments`} component={DepartmentsSection} />
                  <Route path={`${path}/documents`} component={DocumentsSection} />
                  <Route path={`${path}/settings`} component={SettingsSection} />
                  <Route component={ComingSoon} />
                </Switch>
              </div>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </AdminContainerWrapper>
  );
};

export default AdminContainer;
