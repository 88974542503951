import styled from 'styled-components';

import { Typography } from 'antd';

const { Title } = Typography;

const ComingSoonWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  place-items: center;
`;

const ComingSoon = (): JSX.Element => {
  return (
    <ComingSoonWrapper>
      <Title level={1}>Coming Soon</Title>
    </ComingSoonWrapper>
  );
};

export default ComingSoon;
